@use "../global";

.form-container {
	hr {
		width: 100px;
		margin: 24px auto;
		border: none;
		background: global.$light-gray;
		height: 1px;
	}
	.errMsg {
		margin-left: 0;
		padding-bottom: 1px;
		display: block;
		line-height: 1;
		font-weight: 400;
		letter-spacing: 1px;

	}

	.fadeOut {
		opacity: .2;
		filter: alpha(opacity=20);
	}

	#err_privacycheck * {
		text-align:center;
		display: inline-block;
	}

	.formError {
		padding-bottom: 13px;
		display: block;
		.formErrorClose {
			border: solid #252525 2px;
			border-radius: 9px 9px 9px 9px;
			-moz-border-radius: 9px 9px 9px 9px;
			-webkit-border-radius: 9px 9px 9px 9px;
			display: block;
			width: 16px;
			height: 16px;
			position: absolute;
			top: -4px;
			right: -4px;
			color: #efefef;
			background: #333;
			font-weight: 400;
			text-align: center;
			line-height: middle;
			cursor: pointer;
			box-shadow: 1px -1px 3px #888;
			-moz-box-shadow: 1px -1px 3px #888;
			-webkit-box-shadow: 1px -1px 3px #888;
			_z-index:2;
			&:hover {
				background: #666;
			}
		}
		.formErrorContent {
			padding: 10px 12px;
			position:relative;
			color: #fff;
			font-size: 1rem;
			_z-index:1;
		}
		.formErrorArrow {
			width: 15px;
			height: 15px;
			position: absolute;
			bottom: 0;
			left: 20px;
			_z-index:0;
			div {
				margin: 0 auto;
				display: block;
				height: 1px;
				background: #252525;
				line-height: 0px;
				font-size: 0px;
				box-shadow: 0px 2px 3px #888;
				-moz-box-shadow: 0px 2px 3px #888;
				-webkit-box-shadow: 0px 2px 3px #888;
			}
			.line10 {
				width: 19px;
			}
			.line9 {
				width: 17px;
			}
			.line8 {
				width: 15px;
			}
			.line7 {
				width: 13px;
			}
			.line6 {
				width: 11px;
			}
			.line5 {
				width: 9px;
			}
			.line4 {
				width: 7px;
			}
			.line3 {
				width: 5px;
			}
			.line2 {
				width: 3px;
			}
			.line1 {
				width: 1px;
			}
		}
		.formErrorArrowBottom {
			margin: -6px;
			top: 0;
			div {
				box-shadow: none;
				-moz-box-shadow: none;
				-webkit-box-shadow: none;
			}
		}
	}

	.fixed {
		padding-bottom: 0;
		.formErrorClose {
			display: none;
		}
		.formErrorContent {
			margin-top: .5em;
			border-radius: 0;
			-moz-border-radius: 0;
			-webkit-border-radius: 0;
			background: global.$dark-gray;
			color: global.$very-light-gray;
			box-shadow: none;
			-moz-box-shadow: none;
			-webkit-box-shadow: none;
		}
		.formErrorArrow {
			display: none;
		}
		.formErrorArrowBottom {
			display: none;
		}
	}
	.form-privacy-area {
		text-align: center;
		h2 {
			font-weight: 700;
			color: global.$black;
			font-size: 1.4rem;
			margin-bottom: 24px;
		}
		p {
			font-size: 1.3rem;
		}
		.link {
			margin-top: 12px;
			a {
				text-decoration: underline;
				font-size: 1.3rem;
				i {
					display: inline-block;
					margin-right: 10px;
				}
			}
		}
	}
	.submit {
		margin-top: 48px;
		margin-bottom: 128px;
		text-align: center;
		@include global.flex(nowrap,center,center);
		> a {
			box-sizing: border-box;
			display: block;
			min-width: 200px;
			color: global.$dark-gray;
			font-size: 1.4rem;
			margin-right: 24px;
			border: 1px solid global.$light-gray;
			@include global.box-shadow(3px,190,0.5);
			padding: 1em;
			line-height: 1;
			border-radius: 2px;
			i {
				display: inline-block;
				margin-right: 10px;
			}
		}
		input[type="submit"] {
			box-sizing: border-box;
			display: block;
			@include global.appearance();
			min-width: 200px;
			border: none;
			border: 1px solid global.$very-light-gray;
			background: global.$very-light-gray;
			color: global.$black;
			cursor: pointer;
			font-size: 1.4rem;
			font-weight: 700;
			@include global.box-shadow(3px,190,0.5);
			line-height: 1;
			padding: 1em;
			border-radius: 2px;
		}
	}
	.fieldset {
		@include global.flex(nowrap,space-between,center);
		margin-bottom: 2em;
		div.legends {
			flex-basis: 35%;
			margin-right: 24px;
			@include global.flex(nowrap,flex-start,center);
			mark {
				background: global.$very-light-gray;
				color: global.$black;
				font-size: 1.2rem;
				line-height: 1;
				padding: .3em 1em;
				font-weight: 500;
				margin-right: 10px;
				border-radius: 3px;
				&.req {
					background: #ffecec;
					color: #FF0000;
				}
			}
			span {
				font-weight: 500;
				font-size: 1.3rem;
				display: block;
				color: global.$black;
			}
		}
		div.confirm {
			flex: 1;
			font-size: 1.3rem;
			color: global.$black;
		}
		div.input {
			flex: 1;
			input[type="text"],
			input[type="email"],
			input[type="tel"] {
				font-size: 1.3rem;
				box-sizing: border-box;
				@include global.appearance();
				border: none;
				background: global.$very-light-gray;
				padding: 1em;
				width: 100%;
				outline: none;
			}
			textarea {
				font-size: 1.3rem;
				box-sizing: border-box;
				@include global.appearance();
				border: none;
				background: global.$very-light-gray;
				padding: 1em;
				width: 100%;
				min-height: 220px;
				outline: none;
			}
		}
		.error {
			padding: .5em 0;
			font-size: 1.3rem;
			color: #FF0000;
		}
		.radio {
			flex: 1;
			@include global.flex(wrap,space-between,stretch);
			.radio-group {
				width: 49%;
				margin-bottom: 5px;
				input[type="radio"] {
					display: none;
				}
				label {
					cursor: pointer;
					@include global.animation(.2s,linear);
					display: block;
					background-color: global.$very-light-gray;
					color: global.$black;
					padding: 8px 10px;
					font-size: 1.2rem;
					border-radius: 5px;
					@include global.flex(nowrap,flex-start,center);
					.icons {
						margin-right: 5px;
						&:before {
							font-size: 1.4rem;
							line-height: 1;
							font-family: "Ionicons";
							content: "\f3a6";
						}
					}
					small {
						display: inline-block;
					}
					span {
						min-width: 20%;
					}
					small {
						flex: 1;
					}
				}
				input[type="radio"]:checked + label {
					@include global.animation(.2s,linear);
					background-color: global.$black;
					color: #FFF;
					.icons {
						&:before {
							font-family: "Ionicons";
							content: "\f3a7";
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.form-container {
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		.form-privacy-area {
			text-align: center;
			h2 {
				font-weight: 700;
				color: global.$black;
				font-size: 1.3rem;
				margin-bottom: 16px;
			}
			p {
				font-size: 1.2rem;
			}
			.link {
				margin-top: 12px;
				a {
					font-size: 1.2rem;
					i {
						margin-right: 8px;
					}
				}
			}
		}
		.submit {
			margin-top: 36px;
			margin-bottom: 64px;
			text-align: center;
			display: block;
			> a {
				min-width: auto;
				display: block;
				width: 100%;
			}
			input[type="submit"] {
				display: block;
				width: 100%;
				min-width: auto;
			}
		}
		.fieldset {
			display: block;
			div.legends {
				flex-basis: auto;
				margin-right: 0;
				margin-bottom: 6px;
				@include global.flex(nowrap,flex-start,center);
				mark {
					font-size: 1.1rem;
				}
				span {
					font-size: 1.2rem;
				}
			}
			div.confirm {
				flex: none;
			}
			div.input {
				flex: none;
				input[type="text"],
				input[type="email"],
				input[type="tel"] {
					font-size: 1.2rem;
				}
				textarea {
					font-size: 1.2rem;
					min-height: 160px;
				}
				.error {
					padding: .2em 0;
					font-size: 1.2rem;
				}
			}
			.radio {
				flex: none;
				display: block;
				.radio-group {
					width: 100%;
					label {
						font-size: 1.1rem;
						.icons {
							margin-right: 5px;
							&:before {
								font-size: 1.3rem;
							}
						}
					}
				}
			}
		}
	}
}
