@use "../global";

#wrap {
	box-sizing: border-box;
	padding: 3em 0 6em;
	width: global.$width;
	margin-left: auto;
	margin-right: auto;
	&.two {
		@include global.flex(nowrap,space-between,flex-start);
	}
	&.single {
		width: 720px;
		margin-left: auto;
		margin-right: auto;
	}
}

#main {
	flex: 1;
}

#side {
	margin-left: 84px;
	flex-basis: 300px;
}


@media only screen and (max-width: 1120px) {
	#wrap {
		width: auto;
		margin-left: 0;
		margin-right: 0;
		&.single {
			width: auto;
			margin-left: 0;
			margin-right: 0;
		}
	}
	#main {
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
	}
	#side {
		flex-basis: 250px;
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		margin-left: 0;
	}
}

@media screen and (max-width: 800px) {
	#wrap {
		padding-top: 2em;
		margin-top: global.$sp-header-height;
		&.two {
			display: block;
		}
	}
	#main {
		padding-left: 0;
		padding-right: 0;
	}
	#side {
		margin-top: 32px;
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		margin-left: 0;
		flex-basis: auto;
	}
	.home {
		#wrap {
			margin-top: 0;
			padding-top: 0;
			padding-left: global.$sp-side-padding;
			padding-right: global.$sp-side-padding;
		}
	}
	#map + #wrap {
		margin-top: 0;
	}
}

@media screen and (max-width: 320px) {

}
