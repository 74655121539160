.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	display: inline-block;
	width: 1em;
	height: 1em;
	line-height: 1;
	text-transform: none;	
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}