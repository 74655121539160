@use "../global";

.breadcrumbs {
	//background-color: global.$very-light-gray;
	//border-bottom: 1px solid global.$very-light-gray;
	padding: 1em 0;
	margin-bottom: 24px;
	.breadcrumbs-container {
		width: global.$width;
		margin-left: auto;
		margin-right: auto;
		ul {
			@include global.flex(nowrap,flex-start,center);
			li {
				font-size: 1.2rem;
				margin-right: 24px;
				a {
					line-height: 1;
					color: global.$black;
					display: inline-block;
					&:after {
						margin-left: 24px;
						font-family: "Ionicons";
						content: "\f3d3";
						font-size: 1.6rem;
						line-height: 1;
						position: relative;
						top: 1.5px;
					}
					i {
						line-height: 1;
						font-size: 1.6rem;
					}
				}
				span {
					line-height: 1;
					display: inline-block;
					color: global.$dark-gray;
				}
			}
		}
	}
}

.breadcrumbs + #map {
	margin-top: -24px;
}

.title {
	margin-bottom: 32px;
	@include global.flex(wrap,space-between,center);
	h1 {
		//@include mincho();
		font-size: 3.3rem;
		line-height: 1;
		font-weight: 700;
		color: global.$black;
		margin-right: 16px;
	}
	h2 {
		//@include mincho();
		font-size: 1.8rem;
		line-height: 1;
		font-weight: 700;
		color: global.$black;
		margin-right: 16px;
	}
	.description {
		margin-top: 32px;
		flex-basis: 100%;
		font-size: 1.4rem;
		line-height: 1.8;
	}
	span {
		margin-left: 24px;
		color: global.$dark-gray;
		font-family: 'Marcellus SC', serif;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 1;
		text-transform: uppercase;
	}
}

.home {
	.title {
		border-top: 1px solid global.$light-gray;
		padding-top: 16px;
	}
}

#side {
	.title {
		margin-bottom: 0;
		border-bottom: 1px solid global.$light-gray;
		padding-bottom: 16px;
		h2 {
			font-size: 1.6rem;
		}
	}
}

.entry-title {
	@include global.flex(nowrap,space-between,center);
	h1 {
		@include global.mincho();
		font-size: 1.6rem;
		line-height: 1;
		font-weight: 700;
		color: global.$black;
	}
	span {
		@include global.sans();
		display: inline-block;
		margin-left: 16px;
		font-weight: 500;
		font-size: 1.2rem;
		line-height: 1;
		color: global.$black;
		background: #FBFBFB;
		padding: .4em 1em;
		@include global.box-shadow(10px,190,0.5);
		border-radius: 20px;
	}
}

@media only screen and (max-width: 1120px) {
	.breadcrumbs {
		padding: 1em global.$side-padding;
		margin-bottom: 24px;
		.breadcrumbs-container {
			width: auto;
			margin-left: 0;
			margin-right: 0;
		}
	}

}

@media screen and (max-width: 800px) {
	.breadcrumbs {
		display: none;
	}
	.title {
		h1 {
			font-size: 2rem;
		}
		h2 {
			font-size: 1.5rem;
		}
		.description {
			font-size: 1.2rem;
		}
		span {
			font-size: 1.2rem;
			font-weight: 500;
		}
	}
	.title,
	.entry-title {
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		margin-bottom: 32px;
	}
	.home {
		.title {
			padding-top: 16px;
			padding-left: 0;
			padding-right: 0;
		}
	}
}
