// sizing,color
$black: #252525;
$gray: lighten($black,50%);
$dark-gray: darken($gray,15%);
$light-gray: lighten($gray,15%);
$very-light-gray: lighten($light-gray,15%);

$blue: #0e5faf;
$red: #df744a;

$width: 1240px;
$width_tablet: 1024px;
$width_single: 720px;
$header-height: 120px;
$scroll-header-height: 54px;
$breadcrumb-height: 48px;
//$header-category-height: 40px;
$sp-header-height: 48px;
$side-padding: 20px;
$sp-side-padding: 12px;
