@use "../global";

.service {
	.service-container {
		@include global.flex(wrap,space-between,stretch);
		.service {
			flex-basis: 48%;
			margin-bottom: 48px;
			figure {
				.img {
					@include global.background(center,center);
					height: 160px;
				}
				figcaption {
					margin-top: 16px;
					h2 {
						font-weight: bold;
						color: global.$black;
						font-size: 1.6rem;
						line-height: 1.5;
					}
					p {
						color: global.$dark-gray;
						margin-top: 12px;
						font-size: 1.3rem;
						line-height: 1.8;
					}
				}
			}
		}
		hr {
			width: 100px;
			margin: 24px auto;
			height: 1px;
			background: global.$light-gray;
			border: none;
		}
		ul {
			margin-top: 12px;
			li {
				font-size: 1.4rem;
				margin-bottom: 6px;
				font-weight: 600;
				&:last-child {
					margin-bottom: 0;
				}
				&:before {
					font-family: "Ionicons";
					content: "\f363";
					margin-right: 12px;
				}
			}
		}
		.more {
			margin-top: 24px;
			font-size: 1.3rem;
			font-weight: 400;
			text-align: center;
			a {
				display: inline-block;
				font-weight: 500;
				font-size: 1.2rem;
				line-height: 1;
				color: global.$black;
				background: #FBFBFB;
				padding: .6em 1.2em;
				@include global.box-shadow(10px,190,0.5);
				border-radius: 20px;
				&:hover {
					color: #FFF;
					background-color: global.$gray;
				}
			}
			i {
				display: inline-block;
				margin-right: 6px;
			}
		}
	}
}

@media only screen and (max-width: 1120px) {
	.service {
		.service-container {
			padding-left: global.$side-padding;
			padding-right: global.$side-padding;
		}
	}
}

@media screen and (max-width: 800px) {
	.service {
		.service-container {
			padding-left: global.$sp-side-padding;
			padding-right: global.$sp-side-padding;
			flex-wrap: wrap;
			.service {
				flex-basis: 100%;
				margin-bottom: 32px;
			}
			ul {
				li {
					font-size: 1.3rem;
				}
			}
		}
	}
}
