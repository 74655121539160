@use "../global";

.qa-category-list {
	margin-top: 64px;
	box-sizing: border-box;
	@include global.flex(wrap,space-between,stretch);
	.qa-category {
		flex-basis: 48%;
		box-sizing: border-box;
		margin-bottom: 64px;
		h2 {
			font-size: 2.2rem;
			line-height: 1;
			font-weight: bold;
			color: global.$black;
			//border-bottom: 1px solid global.$light-gray;
			padding-bottom: 12px;
		}
		ul {
			margin-top: 24px;
			li {
				@include global.flex(wrap,space-between,center);
				font-size: 1.4rem;
				margin-bottom: 36px;
				font-weight: 500;
				a {
					flex: 1;
				}
				.author {
					margin-top: -8px;
					flex-basis: 28px;
					margin-left: 12px;
					@include global.flex(nowrap,flex-start,center);
					.img {
						@include global.background(center,center);
						width: 28px;
						height: 28px;
						border-radius: 100%;
						@include global.box-shadow(10px,190,0.5);
					}
				}
			}
		}
		.more {
			margin-top: 24px;
			font-size: 1.3rem;
			font-weight: 400;
			text-align: center;
			a {
				display: inline-block;
				font-weight: 500;
				font-size: 1.2rem;
				line-height: 1;
				color: global.$black;
				background: #FBFBFB;
				padding: .6em 1.2em;
				@include global.box-shadow(10px,190,0.5);
				border-radius: 20px;
				&:hover {
					color: #FFF;
					background-color: global.$gray;
				}
			}
			i {
				display: inline-block;
				margin-right: 6px;
			}
		}
	}
}

@media only screen and (max-width: 1120px) {
}

@media screen and (max-width: 800px) {
	.qa-category-list {
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		margin-top: 32px;
		display: block;
		.qa-category {
			flex-basis: auto;
			margin-bottom: 36px;
		}
	}
}
