@use "../global";

.home {
	#hero {
		@include global.background(center,center);
		height: 480px;
		position: relative;
		h1 {
			width: 80%;
			position: absolute;
			@include global.center50();
			text-align: center;
			color: #FFF;
			font-size: 3.2rem;
			line-height: 1.5;
			font-weight: 700;
			text-shadow: 0 0 10px rgba(0,0,0,0.5);
			letter-spacing: 1px;
		}
	}

	.home-container {
		@include global.flex(nowrap,space-between,flex-start);
		.home-left {
			flex: 3;
		}
		.home-right {
			margin-left: 48px;
			flex: 1;
		}
	}

	.home-container-two {
		@include global.flex(nowrap,space-between,flex-start);
		> div {
			flex-basis: 48%;
		}
	}

	.service-container {
		@include global.flex(wrap,flex-start,stretch);
		.service {
			flex-basis: 49.5%;
			&:nth-of-type(odd) {
				margin-right: 1%;
			}
			margin-bottom: 16px;
			a {
				border-radius: 5px;
				display: block;
				padding: 1em;
				&:hover {
					background-color: #EBEBEB;
				}
				figure {
					@include global.background(center,center);
					height: 160px;
				}
				.info {
					margin-top: 16px;
					h3 {
						font-weight: 700;
						color: global.$black;
						font-size: 1.6rem;
						line-height: 1.4;
					}
					p {
						color: global.$dark-gray;
						margin-top: 12px;
						font-size: 1.3rem;
					}
					.more {
						margin-top: 12px;
						font-size: 1.3rem;
						font-weight: 400;
						i {
							display: inline-block;
							margin-right: 6px;
						}
					}
				}
			}
		}
		&.qa {
			.service {
				text-align: center;
				flex-basis: 32%;
				h3 {
					a {
						margin-left: auto;
						margin-right: auto;
						overflow: hidden;
						background-color: global.$very-light-gray;
						text-align: center;
						line-height: 130px;
						color: global.$black;
						font-weight: 600;
						border-radius: 100%;
						width: 130px;
						height: 130px;
						padding: 0;
						display: block;
						&:hover {
							background-color: global.$black;
							color: #FFF;
						}
					}
				}
				.count {
					margin-top: 4px;
					small {
						text-align: center;
						font-size: 1.1rem;
						line-height: 1;
					}
				}
				.more {
					margin-top: 12px;
					a {
						color: global.$dark-gray;
						font-weight: 600;
						font-size: 1.3rem;
						padding: 0;
						border-radius: 0;
						display: inline-block;
						@include global.flex(nowrap,center,center);
						i {
							display: inline-block;
							margin-right: 6px;
						}
						&:hover {
							background: none;
						}
					}
				}
			}
		}
	}

	.about-home {
		ul {
			li {
				a {
					&:before {
						font-family: "Ionicons";
						content: "\f3d1";
						margin-right: 8px;
					}
					font-size: 1.3rem;
					display: block;
					padding: 1em .5em;
					border-bottom: 1px solid global.$light-gray;
					&:hover {
						background-color: global.$very-light-gray;
					}
				}
				&:last-child {
					a {
						border-bottom: none;
					}
				}
			}
		}
	}

	.news-home {
		margin-top: 32px;
		ul {
			li {
				a {
					date {
						color: global.$gray;
						font-size: 1rem;
						line-height: 1;
						display: block;
						margin-bottom: 6px;
					}
					font-size: 1.3rem;
					display: block;
					padding: 1em .5em;
					border-bottom: 1px solid global.$light-gray;
					&:hover {
						background-color: global.$very-light-gray;
					}
				}
				&:last-child {
					a {
						border-bottom: none;
					}
				}
			}
		}
	}

	.blogs {
		ul {
			li {
				overflow: hidden;
				padding: 0 .5em;
				margin-bottom: 12px;
				&:last-child {
					margin-bottom: 0;
				}
				a {
					@include global.flex(nowrap,flex-start,center);
					date {
						margin-right: 24px;
						color: global.$gray;
						display: block;
						font-size: 1rem;
						line-height: 1;
						margin-bottom: 6px;
					}
					font-size: 1.3rem;
					display: block;
				}
			}
		}
	}
}


@media only screen and (max-width: 1120px) {
	.home {
		.home-container {
			padding: 0 global.$side-padding;
			.home-right {
				margin-left: global.$side-padding;
			}
		}
		.home-container-two {
			padding: 0 global.$side-padding;
		}
	}
}

@media screen and (max-width: 800px) {
	.home {
		#hero {
			margin-top: global.$sp-header-height;
			height: 220px;
			h1 {
				font-size: 2rem;
			}
		}
		.home-container {
			padding: 0;
			display: block;
			.home-left {
				flex: none;
			}
			.home-right {
				margin-left: 0;
				flex: none;
			}
		}
		.home-container-two {
			padding: 0;
			display: block;
			> div {
				flex-basis: auto;
				margin-top: 32px;
			}
		}
		.service-container {
			margin-bottom: 32px;
			display: block;
			.service {
				flex-basis: auto;
				margin-bottom: 16px;
				a {
					border-radius: 0;
					display: block;
					padding: 0;
					@include global.flex(nowrap,space-between,flex-start);
					&:hover {
						background: #fff;
					}
					figure {
						@include global.background(center,center);
						flex-basis: 35%;
						min-height: 100px;
						margin-right: 16px;
						height: auto;
					}
					.info {
						padding: 3px 0;
						flex: 1;
						margin-top: 0;
						h3 {
							font-size: 1.4rem;
						}
						p {
							margin-top: 12px;
							font-size: 1.1rem;
						}
						.more {
							display: none;
						}
					}
				}
			}
			&.qa {
				display: flex;
				.service {
					flex-basis: 47%;
					figure {
						display: inline-block;
						overflow: hidden;
						border-radius: 100%;
						width: 100px;
						height: 100px;
					}
					.more {
						margin-top: 12px;
						a {
							color: global.$dark-gray;
							font-weight: 600;
							font-size: 1.3rem;
							@include global.flex(nowrap,center,center);
							i {
								display: inline-block;
								margin-right: 6px;
							}
							small {
								border-radius: 16px;
								margin-left: 12px;
								background-color: global.$very-light-gray;
								padding: .2em 1em;
								line-height: 1;
								font-size: 1rem;
							}
						}
					}
				}
			}
		}
		.news-home {
			margin-top: 32px;
			ul {
				li {
					a {
						padding: 1em 0;
					}
				}
			}
		}
		.blogs {
			margin-top: 32px;
			ul {
				li {
					padding: 0;
				}
			}
		}
	}
}
