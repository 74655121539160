@use "../global";

#overray-header {
	box-sizing: border-box;
	display: none;
	position: fixed;
	width: 100%;
	height: 100%;
	padding-bottom: 24px;
	padding-left: global.$sp-side-padding;
	padding-right: global.$sp-side-padding;
	top: 0;
	left: 0;
	background-color: rgba(global.$very-light-gray,0.95);
	//background: url(../img/bg_search.jpg) no-repeat top left;
	//background-size: cover;
	z-index: 999999;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
	#overray-menu-container {
		position: relative;
		#close {
			position: absolute;
			top: 10px;
			right: 8px;
			a {
				font-size: 2.6rem;
				color: global.$gray;
				i {
					line-height: 1;
					display: block;
				}
			}
		}
		#overray-contents {
			.logo {
				@include global.flex(nowrap,space-between,center);
				height: global.$sp-header-height;
				line-height: global.$sp-header-height;
				a {
					display: block;
					width: 24px;
					svg {
						width: 24px;
						display: block;

					}
				}
			}
			.search {
				margin-top: 24px;
				fieldset {
					margin-bottom: 5px;
					input[type="search"] {
						display: block;
						box-sizing: border-box;
						width: 100%;
						@include global.appearance();
						background-color: #FFF;
						border: none;
						padding: .8em;
						font-size: 1.4rem;
						outline: none;
					}
				}
				.submit {
					input[type="submit"] {
						width: 100%;
					}
				}
			}
			> hr {
				width: 100px;
				border: none;
				background: global.$gray;
				height: 1px;
				margin-top: 36px;
				margin-bottom: 36px;
			}
			.tel {
				margin-top: 24px;
				text-align: center;
				font-weight: bold;
				a {
					font-size: 2.4rem;
					color: global.$gray;
					i {
						display: inline-block;
						margin-right: 10px;

					}
				}
				p {
					margin: 0;
					font-size: 1.3rem;
					color: global.$gray;
					font-weight: 500;
				}
			}
			.time {
				margin-top: 5px;
				text-align: center;
				font-size: 1.2rem;
				line-height: 1;
			}
			.g-nav {
				margin-top: 24px;
				a {
					display: block;
					padding: 6px 0;
					font-size: 1.6rem;
					color: global.$dark-gray;
					font-weight: 700;
				}
			}
			.socials {
				margin-top: 24px;
				font-size: 2.6rem;
				line-height: 1;
				@include global.flex(nowrap,center,center);
				a {
					position: relative;
					margin: 0 12px;
					i {
						position: relative;
						display: block;
						color: global.$dark-gray;
					}
				}
			}
			.f-nav {
				padding-bottom: 128px;
				ul {
					@include global.flex(wrap,space-between,center);
					li {
						flex-basis: 50%;
						font-size: 1.3rem;
						margin-bottom: 12px;
						a {
							color: global.$gray;
							&:hover {
								color: global.$dark-gray;
							}
						}
					}
				}
			}
		}
	}
}

#sp-header {
	display: none;
	background-color: rgba(255,255,255,0.95);
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 9999;
	@include global.box-shadow(10px,190,0.5);
	.header-container {
		@include global.flex(nowrap,space-between,center);
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		height: global.$sp-header-height;
		.logo {
			flex-basis: 24px;
			svg,
			img {
				display: block;
				width: 24px;
			}
		}
		.g-nav {
			flex: 1;
			@include global.flex(nowrap,center,center);
			a {
				margin-right: 48px;
				font-size: 1.3rem;
				font-weight: 600;
			}
		}
		.sub-nav {
			flex-basis: 200px;
			@include global.flex(nowrap,flex-end,center);
			a {
				width: 28px;
				height: 28px;
				line-height: 28px;
				text-align: center;
				display: block;
				color: global.$black;
				margin-left: 12px;
				i {
					display: inline-block;
					font-size: 2.3rem;
				}
			}
		}
	}
}

#scroll-header {
	background-color: rgba(255,255,255,0.95);
	position: fixed;
	width: 100%;
	top: 0 - global.$header_height;
	left: 0;
	z-index: 9998;
	@include global.flex(nowrap,space-between,center);
	padding-left: global.$side-padding;
	padding-right: global.$side-padding;
    box-sizing: border-box;
    height: global.$scroll-header-height;
    border-bottom: 1px solid #f1f1f1;
	@include global.animation(.2s,ease-out);
	@include global.box-shadow(10px,190,0.2);
	&.active {
		@include global.animation(.2s,ease-in);
		top: 0;
	}
	.header-container {
		width: global.$width;
		margin-left: auto;
		margin-right: auto;
		@include global.flex(nowrap,space-between,center);
		.logo {
			flex-basis: 150px;
			svg,
			img {
				display: block;
				width: 24px;
			}
		}
		.g-nav {
			flex: 1;
			@include global.flex(nowrap,center,center);
			a {
				margin-right: 48px;
				font-size: 1.3rem;
				font-weight: 600;
			}
		}
		.sub-nav {
			flex-basis: 150px;
			@include global.flex(nowrap,flex-end,center);
			a {
				width: 28px;
				height: 28px;
				line-height: 28px;
				background-color: #FFF;
				text-align: center;
				display: block;
				border-radius: 100%;
				color: global.$black;
				margin-left: 12px;
				@include global.box-shadow(10px,190,0.2);
				border: 1px solid global.$light-gray;
				i {
					display: inline-block;
					font-size: 1.8rem;
				}
			}
		}
	}
}


#header {
	height: 125px;
	.upper-header {
		box-sizing: border-box;
		.container {
			box-sizing: border-box;
			height: 70px;
			width: global.$width;
			margin-left: auto;
			margin-right: auto;
			@include global.flex(nowrap,space-between,center);
		}
		.logo {
			flex-basis: 230px;
			svg {
				width: 230px;
			}
		}
		.sub-nav {
			@include global.flex(nowrap,flex-end,center);
			.info {
				line-height: 1;
				font-size: .8rem;
				text-align: center;
				margin-bottom: 3px;
				font-weight: 600;
			}
			.inquiry {
				margin-right: 24px;
				a {
					height: 30px;
					box-sizing: border-box;
					@include global.flex(nowrap,center,center);
					width: 180px;
					padding: 0 1em;
					font-size: 1.2rem;
					text-align: center;
					font-weight: 600;
					background-color: #fbfbfb;
					border: 1px solid #f1f1f1;
					color: global.$black;
					line-height: 1;
					border-radius: 3px;
					i {
						font-size: 2rem;
						display: inline-block;
						margin-right: 4px;
					}
				}

			}
			.tel {
				a {
					height: 30px;
					box-sizing: border-box;
					@include global.flex(nowrap,flex-start,center);
					font-size: 2rem;
					line-height: 1;
					margin-bottom: 0;
					font-weight: 600;
					font-family: 'Helvetica';
					color: global.$black;
					i {
						position: relative;
						top: -2px;
						font-size: 1.8rem;
						display: inline-block;
						margin-right: 4px;
					}
				}
				.time {
					margin: 0;
					text-align: center;
					small {
						display: block;
						font-size: .8rem;
						line-height: 1;
					}
				}
			}
		}
	}
	.lower-header {
		box-sizing: border-box;
		border-top: 1px solid #f1f1f1;
		border-bottom: 1px solid #f1f1f1;
		background-color: #FBFBFB;
		height: 55px;
		.container {
			box-sizing: border-box;
			height: 55px;
			width: global.$width;
			margin-left: auto;
			margin-right: auto;
			@include global.flex(nowrap,space-between,center);
		}
		.g-nav {
			@include global.flex(nowrap,flex-start,center);
			a {
				margin-right: 48px;
				font-size: 1.4rem;
				font-weight: 600;
			}
		}
		.search {
			fieldset {
				height: 36px;
				position: relative;
				i {
					position: absolute;
					top: 50%;
					left: 12px;
					width: auto;
					height: auto;
					-webkit-transform: translateY(-50%);
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					font-size: 2rem;
					color: #CCC;
				}
				input[type="search"] {
					box-sizing: border-box;
					border: 1px solid #EBEBEB;
					@include global.appearance();
					width: 260px;
					height: 36px;
					padding: 0 16px 0 32px;
					background-color: #FFF;
					outline: none;
					font-size: 1.3rem;
					&::placeholder {
						color: #CCC;
					}
				}
			}
		}
	}
}


@media only screen and (max-width: 1120px) {
	#scroll-header {
		.header-container {
			width: 100%;
			margin-left: 0;
			margin-right: 0;
		}
	}
	#header {
		.upper-header,
		.lower-header {
			.container {
				padding: 0 global.$side-padding;
				width: auto;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	#header {
		.lower-header {
			.search {
				fieldset {
					input[type="search"] {
						width: 200px;
					}
				}
			}
		}
	}
	#scroll-header {
		.header-container {
			.logo {
				flex-basis: 100px;
			}
			.sub-nav {
				flex-basis: 100px;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	#sp-header {
		display: block;
	}
	#scroll-header,
	#header {
		display: none;
	}
}

@media screen and (max-width: 320px) {

}
