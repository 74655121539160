@use "../global";

#map {
	height: 380px;
}

.authors-list {
	figure {
		margin-bottom: 48px;
		@include global.flex(nowrap,space-between,flex-start);
		.img {
			@include global.background(center,center);
			width: 128px;
			height: 128px;
			border-radius: 100%;
			@include global.box-shadow(10px,190,0.5);
			margin-right: 48px;
		}
		figcaption {
			flex: 1;
			font-size: 1.3rem;
			line-height: 1;
			h2 {
				font-size: 1.4rem;
				color: global.$black;
				font-weight: 600;
				margin-bottom: 24px;
				border: none !important;
				padding: 0 !important;
			}
			.author-profile {
				font-size: 1.3rem;
				line-height: 1.7;
			}
		}
	}
}

@media screen and (max-width: 800px) {
	.breadcrumbs + #map {
		margin-top: global.$sp-header-height;
		height: 230px;
	}
	.authors-list {
		figure {
			display: block;
			text-align: center;
			.img {
				margin-left: auto;
				margin-right: auto;
				@include global.background(center,center);
				width: 84px;
				height: 84px;
				margin-bottom: 24px;
			}
			figcaption {
				flex: auto;
				font-size: 1.3rem;
				line-height: 1;
				h2 {
					font-size: 1.3rem;
					color: global.$black;
					font-weight: 600;
					margin-bottom: 12px;
				}
				.author-profile {
					text-align: left;
					font-size: 1.2rem;
					line-height: 1.7;
				}
			}
		}
	}
}
