@use "../global";

.archive-list {
	ul {
		li {
			@include global.flex(nowrap,space-between,center);
			padding: 2em 0;
			border-bottom: 1px solid global.$very-light-gray;
			font-size: 1.4rem;
			date {
				font-size: 1.1rem;
				flex-basis: 100px;
				font-weight: 600;
				color: global.$gray;
			}
			.category {
				flex-basis: 150px;
				flex-shrink: 1;
				font-size: 1.2rem;
				a {
					@include global.sans();
					display: inline-block;
					//margin-left: 16px;
					font-weight: 500;
					font-size: 1.2rem;
					line-height: 1;
					color: global.$black;
					background: #FBFBFB;
					padding: .4em 1em;
					@include global.box-shadow(10px,190,0.5);
					border-radius: 20px;
				}
			}
			.archive-title {
				font-weight: 600;
				flex: 1;
				a {
					&:before {
						margin-right: 12px;
						font-family: "Ionicons";
						content: "\f3d3";
						line-height: 1;
					}
				}
			}
			.author {
				margin-left: 24px;
				flex-basis: 36px;
				@include global.flex(nowrap,flex-start,center);
				.img {
					@include global.background(center,center);
					width: 36px;
					height: 36px;
					border-radius: 100%;
					@include global.box-shadow(10px,190,0.5);
					margin-right: 12px;
				}
			}
		}
	}
}

.relative {
	.archive-list {
		ul {
			li {
				justify-content: flex-start;
				date {
					flex-basis: auto;
					margin-right: 24px;
				}
				a {
					flex: 1;
				}
			}
		}
	}
	&.service {
		.archive-list {
			ul {
				li {
					a {
						&:before {
							font-family: "Ionicons";
							content: "\f3d1";
							margin-right: 8px;
						}
					}
				}
			}
		}
	}
}

.pagination {
	text-align: center;
	margin-top: 64px;
	.pager {
		@include global.flex(nowrap,center,center);
    column-gap: 24px;
		a,
		.prev,
    .next {
			font-weight: 500;
			display: block;
			font-size: 1.6rem;
      font-weight: bold;
			line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 12px;
      &.disabled {
        color: #CCC;
      }
		}
	}
}

@media screen and (max-width: 800px) {
	.archive-list {
		ul {
			li {
				flex-wrap: wrap;
				font-size: 1.3rem;
				padding-top: 1.4em;
				padding-bottom: 1.4em;
				padding-left: global.$sp-side-padding;
				padding-right: global.$sp-side-padding;
				date {
					order: 1;
					font-size: 1rem;
					flex: 1;
				}
				.category {
					order: 2;
					flex-basis: auto;
					a {
						font-size: 1rem;
					}
				}
				.archive-title {
					order: 4;
					font-weight: 600;
					flex-basis: 100%;
				}
				.category + .archive-title {
					margin-top: 10px;
				}
				.author {
					order: 3;
					margin-left: 12px;
					flex-basis: 24px;
					text-align: right;
					.img {
						display: inline-block;
						width: 24px;
						height: 24px;
						margin-right: 0;
					}
				}
			}
		}
	}
	.relative {
		.archive-list {
			ul {
				li {
					display: block;
					padding-left: 0;
					padding-right: 0;
					date {
						display: block;
						flex: none;
						margin-right: 0;
					}
					a {
						margin-top: 6px;
						display: inline-block;
						flex: none;
					}
				}
			}
		}
	}
	.pagination {
		margin-top: 32px;
		margin-bottom: 64px;
	}
}
