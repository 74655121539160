@use "../global";

#footer {
	padding: 0 0 12px;
	background: rgb(69,72,77);
	background: url(../img/footer_bg.png) repeat ,linear-gradient(to top, rgba(69,72,77,1) 0%,rgba(0,0,0,1) 100%);
	color: #FFF;
	.footer-container {
		width: global.$width;
		margin-left: auto;
		margin-right: auto;
		font-size: 1.2rem;
		@include global.flex(nowrap,space-between,flex-start);
		.footer-company-area {
			flex-basis: 30%;
			a {
				color: #FFF;
			}
			.logo {
				margin-top: 16px;
				svg {
					line-height: 1;
					display: block;
					width: 220px;
					fill: #FFF;
				}
			}
			address {
				display: block;
				margin-top: 36px;
				ul {
					li {
						margin-bottom: 12px;
						&.tel {
							font-size: 2.6rem;
							line-height: 1;
							margin-bottom: 0;
							font-weight: 600;
							font-family: 'Helvetica';
						}
						small {
							font-size: 1.1rem;
						}
					}
				}
			}
		}
		.footer-nav {
			flex-basis: 20%;
			h5 {
				padding: 18px 0 2px;
				font-weight: 600;
				@include global.mincho();
				font-size: 1.7rem;
				line-height: 1;
				border-left: 1px solid global.$gray;
        padding-left: 8px;
        color: #FFF;
				span {
					display: block;
					font-family: 'Marcellus SC', serif;
					font-weight: 400;
					font-size: 1rem;
					margin-top: 10px;
				}
			}
			ul {
				margin-top: 24px;
				margin-left: 6px;
				li {
					font-size: 1.2rem;
					line-height: 1;
					margin-bottom: 8px;
					&:last-child {
						margin-bottom: 0;
					}
					a {
						color: #FFF;
					}
				}
			}
		}
		.g-nav {
			flex: 1;
			@include global.flex(nowrap,flex-end,center);
			text-align: center;
			a {
				margin: 0 24px;
				font-size: 1.3rem;
				font-weight: 500;
			}
		}
	}
	.f-nav {
		margin-top: 48px;
		text-align: center;
		ul {
			@include global.flex(wrap,center,center);
			li {
				margin: 0 1em;
				font-size: 1.2rem;
				line-height: 1;
				a {
					color: #FFF;
				}
			}
		}
	}
	.socials {
		margin-top: 24px;
		font-size: 2.6rem;
		line-height: 1;
		@include global.flex(nowrap,center,center);
		a {
			position: relative;
			margin: 0 12px;
			i {
				position: relative;
				display: block;
				color: #FFF;
			}
		}
	}
	#copyright {
		margin-top: 24px;
		text-align: center;
		font-size: 1rem;
		line-height: 1;
		letter-spacing: 1px;
	}
}


@media only screen and (max-width: 1120px) {
	#footer {
		.footer-container {
			width: auto;
			padding-left: global.$side-padding;
			padding-right: global.$side-padding;
		}
	}
}

@media screen and (max-width: 800px) {
	#footer {
		padding: 12px 0;
		.footer-container {
			flex-wrap: wrap;
			.footer-company-area {
				flex-basis: 100%;
				margin-bottom: 32px;
				.logo {
					text-align: center;
					svg {
						width: 200px;
						margin: auto;
					}
				}
				address {
					display: block;
					margin-top: 12px;
					ul {
						li {
							font-size: 1rem;
							text-align: center;
							margin-bottom: 12px;
							&:last-child {
								font-size: 2rem;
							}
						}
					}
				}
			}
			.footer-nav {
				flex-basis: 47%;
				margin-bottom: 32px;
				h5 {
					padding: 0;
					font-weight: 600;
					font-size: 1.4rem;
					border-left: none;
					border-top: 1px solid global.$gray;
					padding-top: 12px;
					span {
						font-size: 1rem;
						margin-top: 4px;
					}
				}
				ul {
					margin-top: 26px;
					margin-left: 0;
					li {
						font-size: 1.1rem;
						line-height: 1;
					}
				}
			}
		}
		.f-nav {
			ul {
				li {
					font-size: 1.1rem;
				}
			}
		}
		#copyright {
			margin-top: 32px;
			font-size: .8rem;
		}
	}
}

.uk-modal-dialog {
  border-radius: 5px;
  overflow: hidden;
}

.uk-modal-header  {
  border-bottom: none;
  padding-top: 40px;
  padding-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  .uk-modal-title {
    font-size: 2.5rem;
    font-weight: 600;
  }
}

.uk-modal-body {
  padding-left: 40px;
  padding-right: 40px;
  p {
    font-size: 1.4rem;
    line-height: 1.8;
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.uk-modal-footer {
  border-top: none;
  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      margin-left: 6px;
      font-size: 1.4rem;
      line-height: 1;
    }
  }
  .uk-button {
    margin-top: 24px;
    background-color: global.$black;
    color: #FFF;
    font-weight: 600;
    font-size: 1.4rem;
    border: 1px solid #f1f1f1;
  }
}

@media screen and (max-width: 800px) {
  #modal {
    padding-top: 100px;
  }
  .uk-modal-header  {
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 20px;
    .uk-modal-title {
      font-size: 2rem;
    }
  }
  .uk-modal-body {
    padding-left: 30px;
    padding-right: 30px;
    p {
      font-size: 1.2rem;
    }
  }
  .uk-modal-footer {
    .uk-button {
      font-size: 1.2rem;
    }
  }
}
