@use "sass:map";
@use "variables";

// function
@mixin sans() {
	font-family: "Source Han Sans JP","Noto Sans JP","Yu Gothic",YuGothic,"ヒラギノ角ゴシック Pro","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,Osaka,"ＭＳ Ｐゴシック","MS PGothic",sans-serif;
}

@mixin mincho() {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@mixin background($position-x,$position-y) {
	background-position: $position-x $position-y;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin background-contain($position-x,$position-y) {
	background-position: $position-x $position-y;
	background-repeat: no-repeat;
	background-size: contain;
}

@mixin appearance() {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

@mixin animation($seconds,$function) {
	-webkit-transition: all $seconds;
	-moz-transition: all $seconds;
	-ms-transition: all $seconds;
	 -o-transition: all $seconds;
	transition: all $seconds;
	-webkit-transition-timing-function: $function;
	transition-timing-function: $function;
}

@mixin flex($wrap,$justify,$align) {
	display: -webkit-flex;
	display: flex;
	flex-wrap: $wrap;
	-webkit-justify-content: $justify;
	justify-content: $justify;
	align-items: $align;
}

@mixin center50() {
	top: 50%;
	left: 50%;
	width: auto;
	height: auto;
	-webkit-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
}

@mixin center50-y() {
	top: 50%;
	width: auto;
	height: auto;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

@mixin center50-w() {
	left: 50%;
	width: auto;
	height: auto;
	-webkit-transform: translateW(-50%);
	-ms-transform: translateW(-50%);
	-moz-transform: translateW(-50%);
	transform: translateW(-50%);
}

@mixin bottombg-shadow($depth) {
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background:rgba(0,0,0,0) linear-gradient(to top, rgba(0,0,0,$depth) 20%, rgba(0,0,0,0) 100%);
}

@mixin box-shadow($size,$color,$opacity) {
	box-shadow: 0 0 $size rgba($color,$color,$color,$opacity);
}

@mixin newmark() {
	mark {
		position: absolute;
		top: 0;
		left: 0;
		background: $color1;
		font-weight: 600;
		color: #FFF;
		padding: .4em 1em;
		text-align: center;
		line-height: 1;
		font-size: 1.2rem;
		i {
			display: inline-block;
			margin-right: 6px;
		}
	}
}

@mixin newmark-sp() {
	mark {
		color: #FFF;
		padding: .3em .7em;
		text-align: center;
		line-height: 1;
		font-size: 1rem;
	}
}

@mixin entries-horizontal($count,$img_height) {
	@include flex(wrap,space-between,stretch);
	.post-list-item {
		position: relative;
		flex-basis: $count;
		box-sizing: border-box;
		margin-bottom: 3%;
		@include newmark();
		&:nth-of-type(3n) {
			margin-right: 0;
		}
		.thumbnail {
			> a {
				display: block;
				overflow: hidden;
				text-align: center;
				border-radius: 5px;
				@include background(center,center);
				height: $img_height;
				@include box-shadow(10px,190,0.3);
			}
			.caption {
				margin-top: 16px;
				position: relative;
				h3 {
					font-size: 1.3rem;
					margin-top: .5em;
					margin-bottom: 0;
					font-weight: 400;
					min-height: 40px;
				}
				date {
					display: block;
					font-size: 1.2rem;
					line-height: 1;
					font-family: 'Bitter';
					color: $light-gray;
					margin-bottom: 6px;
				}
				.publisher {
					font-size: 1.1rem;
					line-height: 1;
					font-weight: 300;
					margin-bottom: 12px;
				}
				.tags {
					margin-top: 6px;
					@include flex(wrap,flex-start,center);
					font-size: .9rem;
					a {
						letter-spacing: 1px;
						margin-bottom: 6px;
						padding: .2em .8em;
						font-weight: 300;
						border-radius: 20px;
						border: 1px solid $light-gray;
						margin-right: 6px;
						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

@mixin entries-horizontal-sp() {
	display: block;
	.post-list-item {
		flex-basis: auto;
		margin-bottom: 6%;
		box-sizing: border-box;
		@include newmark-sp();
		.thumbnail {
			@include flex(nowrap,space-between,flex-start);
			> a {
				flex-basis: 100px;
				height: auto;
				min-height: 80px;
				margin-right: 12px;
				border-radius: 3px;
				@include background(center,center);
			}
			.caption {
				margin-top: 0;
				flex: 1;
				position: relative;
				h3 {
					font-size: 1.3rem;
				}
				date {
					font-size: 1.2rem;
				}
				.publisher {
					position: relative;
				}
			}
		}
	}
}

@mixin entries-horizontal-compact($count,$img_height) {
	@include flex(wrap,space-between,stretch);
	.post-list-item {
		flex-basis: $count;
		box-sizing: border-box;
		margin-bottom: 3%;
		&:nth-of-type(3n) {
			margin-right: 0;
		}
		.thumbnail {
			> a {
				display: block;
				overflow: hidden;
				height: $img_height;
				text-align: center;
				border-radius: 0;
				@include background(center,center);
				height: 140px;
				@include box-shadow(10px,190,0.3);
			}
			.caption {
				position: relative;
				h3 {
					font-size: 1.2rem;
					margin-top: 1em;
					margin-bottom: 0;
				}
				.entries-meta {
					date {
						display: block;
						margin-top: 1em;
						margin-bottom: 1em;
						font-size: 1.2rem;
						line-height: 1;
						font-family: 'Bitter';
						color: $light-gray;
					}
				}
			}
		}
	}
}

@mixin entries-vertical() {
	.post-list-item {
		position: relative;
		box-sizing: border-box;
		margin-bottom: 3em;
		@include newmark();
		&:last-of-type {
			margin-bottom: 0;
		}
		.thumbnail {
			@include flex(nowrap,space-between,stretch);
			> a {
				flex-basis: 30%;
				display: block;
				text-align: center;
				border-radius: 5px;
				@include background(center,center);
				min-height: 70px;
				@include box-shadow(10px,190,0.3);
				margin-right: 32px;
			}
			.caption {
				flex: 1;
				position: relative;
				h3 {
					font-size: 1.5rem;
					margin-bottom: 16px;
					font-weight: 400;
				}
				p {
					font-size: 1.2rem;
					font-weight: 300;
				}
				date {
					display: block;
					margin-bottom: 16px;
					font-size: 1.2rem;
					line-height: 1;
					font-family: 'Bitter';
					color: $light-gray;
					margin-bottom: 6px;
				}
				.publisher {
					font-size: 1.1rem;
					line-height: 1;
					font-weight: 300;
					margin-bottom: 12px;
				}
				.tags {
					margin-top: 6px;
					@include flex(wrap,flex-start,center);
					font-size: .9rem;
					a {
						letter-spacing: 1px;
						margin-bottom: 6px;
						padding: .2em .8em;
						font-weight: 300;
						border-radius: 20px;
						border: 1px solid $light-gray;
						margin-right: 6px;
						&:last-of-type {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

@mixin entries-vertical-sp() {
	display: block;
	.post-list-item {
		flex-basis: auto;
		margin-bottom: 6%;
		box-sizing: border-box;
		@include newmark-sp();
		.thumbnail {
			@include flex(nowrap,space-between,flex-start);
			> a {
				flex-basis: 100px;
				height: auto;
				min-height: 80px;
				margin-right: 12px;
				border-radius: 3px;
				@include background(center,center);
			}
			.caption {
				margin-top: 0;
				flex: 1;
				h3 {
					font-size: 1.3rem;
				}
				date {
					font-size: 1.2rem;
				}
				p {
					display: none;
				}
			}
		}
	}
}
