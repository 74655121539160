@use "../global";

html {
	overflow-x:hidden;
	overflow-y:auto;
	font-size: 62.5%;
}

body {
	-webkit-text-size-adjust: 100%;
	@include global.sans();
	font-size: 10px;
	font-size: 1.0rem;
	line-height: 1.5;
	position: relative;
	color: global.$dark-gray;
	padding: 0;
	margin: 0;
	background: transparent;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	letter-spacing: 0.6px;
	font-weight: 400;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-feature-settings: "palt";
	font-feature-settings: "palt";
	text-align: justify;
	text-justify: inter-ideograph;
	&.locked {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

a {
	color: global.$black;
	text-decoration: none;
	@include global.animation(.2s,ease-in);
	&:hover {
    text-decoration: none;
		@include global.animation(.2s,ease-in);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: global.$black;
}
