@use "../global";

#side {
	.widget {
		.title {
			padding-left: 0;
			padding-right: 0;
		}
		margin-bottom: 64px;
		ul {
			margin-top: 16px;
			li {
				width: 100%;
				margin-bottom: 24px;
				text-indent: -8px;
				padding-left: 8px;
				a {
					font-size: 1.3rem;
					font-weight: 500;
					date {
						font-weight: 400;
						color: global.$gray;
						font-weight: 600;
						font-size: 1rem;
						display: block;
						margin-bottom: 4px;
						letter-spacing: 0;
					}
					&:before {
						font-family: "Ionicons";
						content: "\f3d1";
						margin-right: 8px;
					}
					small {
						font-weight: 400;
						margin-left: 24px;
						display: inline-block;
						font-size: 1rem;
						color: global.$gray;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			&.width-date {
				li {
					text-indent: 0;
					padding-left: 0;
					margin-bottom: 24px;
					a {
						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
}
