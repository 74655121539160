@use "../global";

#entry-body {
	margin-top: 64px;
	&.page {
		margin-top: 0;
	}
	h1 {
		font-size: 3.3rem;
		line-height: 1.4;
		font-weight: bold;
		color: global.$black;
		margin-right: 16px;
	}
	#thumbnail {
		margin-top: 48px;
		text-align: center;
		img {
			max-width: 100%;
			display: inline-block;
		}
	}
	.entry-meta {
		margin-top: 48px;
		@include global.flex(nowrap,space-between,center);
		date {
			font-size: 1.3rem;
			line-height: 1;
			margin-right: 48px;
		}
		.author {
			@include global.flex(nowrap,flex-start,center);
			.img {
				@include global.background(center,center);
				width: 36px;
				height: 36px;
				border-radius: 100%;
				@include global.box-shadow(10px,190,0.5);
				margin-right: 12px;
			}
			figcaption {
				font-weight: 600;
				font-size: 1.3rem;
				line-height: 1;
			}
		}
		.socials {
			flex: 1;
			font-size: 2.6rem;
			line-height: 1;
			@include global.flex(nowrap,flex-end,center);
			a {
				position: relative;
				margin-right: 24px;
				&:last-of-type {
					margin-right: 0;
				}
				i {
					position: relative;
					display: block;
				}
				&.facebook {
					color: #3B5999;
				}
				&.twitter {
					color: #55ACEE;
				}
				&.hatena {
					top: -3px;
					font-size: 3rem;
					color: #2C6EBD;
				}
				&.google {
					color: #CC3732;
				}
				&.pocket {
					color: #DC3D50;
				}
			}
		}
	}
	mark {
		display: block;
		background: none;
		font-size: 4rem;
		color: global.$light-gray;
		font-weight: 600;
		font-family: 'Helvetica';
	}
	.entry-body {
		margin-top: 48px;
		font-size: 1.6rem;
		line-height: 1.8;
		&.answer {
			border-top: 1px solid global.$light-gray;
			padding-top: 24px;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		ul,
		ol,
		p,
		blockquote,
		table,
		.arrow-down {
			margin-bottom: 2em;
		}
		ul,
		ol {
			margin-left: 20px;
			li {
				margin-bottom: .5em;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		ul {
			li {
				list-style: square outside;
			}
		}
		ol {
			li {
				list-style: decimal outside;
			}
		}
		strong {
			font-weight: 600;
			color: global.$black;
		}
		blockquote {
			background-color: global.$very-light-gray;
			padding: 1em;
			p {
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: global.$black;
			font-weight: 600;
			margin-bottom: 1em;
		}
		h2 {
			padding: 12px 0;
			border-top: 1px solid global.$gray;
			border-bottom: 1px solid global.$gray;
			font-size: 2rem;
			color: global.$black;
		}
		h3 {
			border-bottom: 1px solid global.$gray;
			padding-bottom: 6px;
			font-size: 1.8rem;
		}
		img {
			display: inline-block;
			margin-bottom: 2em;
			max-width: 100%;
			height: auto;
		}
		a {
			text-decoration: underline;
		}
		.yt-wrapper {
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 25px;
			height: 0;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		.gmap {
			position: relative;
			padding-bottom: 56.25%;
			padding-top: 30px;
			height: 0;
			overflow: hidden;
			iframe,
			object,
			embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		table {
			width: 100%;
			box-sizing: border-box;
			display: table;
			table-layout: fixed;
			border-collapse: separate;
			border-spacing: 4px;
			tr {
				box-sizing: border-box;
				display: table-row;
				th {
					color: global.$black;
					background-color: global.$very-light-gray;
					font-weight: 600;
				}
				th,
				td {
					font-size: 1.4rem;
					padding: .5em;
				}
			}
		}
		.arrow-down {
			text-align: center;
			i {
				font-size: 3rem;
			}
		}
		&.about {
			table {
				font-size: 1.4rem;
				width: 100%;
				box-sizing: border-box;
				display: table;
				table-layout: fixed;
				border-collapse: collapse;
				border-spacing: 0;
				tr {
					box-sizing: border-box;
					display: table-row;
					th {
						width: 25%;
						font-weight: 700;
						background: none;
						@include global.mincho();
						text-align: left;
					}
					th,
					td {
						padding: 1.2em 0;
						border-bottom: 1px solid global.$very-light-gray;
						ul {
							margin-left: 0;
							@include global.flex(wrap,space-between,flex-start);
							&.w100 {
								display: block;
							}
							li {
								list-style: none;
								flex-basis: 45%;
							}
						}
					}
				}
			}
		}
		.price {
			margin-bottom: 2em;
			padding: 1em 1em 1em 48px;
			position: relative;
			@include global.box-shadow(10px,190,0.5);
			border-radius: 3px;
			font-weight: 600;
			color: global.$black;
			font-size: 1em;
			background-color: #FFF;
			&:before {
				position: absolute;
				top: 50%;
				left: 1em;
				display: block;
				font-family: "Ionicons";
				content: "\f4f2";
				border-radius: 100%;
				width: 24px;
				height: 24px;
				line-height: 24px;
				text-align: center;
				background-color: #FFF;
				@include global.box-shadow(10px,190,0.5);
				font-size: 1.2rem;
				-webkit-transform: translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
			}
		}
		.answer.author {
			margin-top: 48px;
			@include global.flex(nowrap,flex-end,center);
			span {
				display: block;
				font-weight: 300;
				margin-right: 12px;
			}
			.img {
				@include global.background(center,center);
				width: 40px;
				height: 40px;
				border-radius: 100%;
				@include global.box-shadow(10px,190,0.5);
				margin-right: 12px;
			}
			figcaption {
				flex: 1;
				h4 {
					font-size: 1.4rem;
					color: global.$black;
					font-weight: 600;
					margin-bottom: 0;
				}
			}
		}
	}
	.entry-footer {
		margin-top: 48px;
		hr {
			width: 120px;
			margin: 0 auto 36px;
			border: none;
			background: global.$light-gray;
			height: 1px;
		}
		.entry-tags {
			text-align: center;
			@include global.flex(wrap,center,center);
			margin-bottom: 24px;
			a {
				display: inline-block;
				margin: 0 8px 12px;
				font-weight: 500;
				font-size: 1.2rem;
				line-height: 1;
				color: global.$black;
				background: #FBFBFB;
				padding: .4em 1em;
				border: 1px solid global.$light-gray;
				border-radius: 4px;
			}
		}
		.socials {
			flex: 1;
			font-size: 2.6rem;
			line-height: 1;
			@include global.flex(nowrap,center,center);
      column-gap: 24px;
			a {
				position: relative;
				i,
        img {
					position: relative;
					display: block;
				}
        img {
          width: 32px;
        }
				&.hatena {
					top: -3px;
					font-size: 3rem;
					color: #2C6EBD;
          font-size: 4rem;
				}
				&.pocket {
					color: #DC3D50;
				}
			}
		}
		.author {
			margin-top: 48px;
			@include global.flex(nowrap,flex-start,flex-start);
			.img {
				@include global.background(center,center);
				width: 84px;
				height: 84px;
				border-radius: 100%;
				@include global.box-shadow(10px,190,0.5);
				margin-right: 48px;
			}
			figcaption {
				flex: 1;
				font-size: 1.3rem;
				line-height: 1;
				h4 {
					font-size: 1.4rem;
					color: global.$black;
					font-weight: 600;
					margin-bottom: 24px;
				}
				.author-profile {
					font-size: 1.3rem;
					line-height: 1.7;
				}
			}
		}
	}
	.relative {
		border-top: 1px solid global.$very-light-gray;
		padding-top: 32px;
		margin-top: 32px;
		h3 {
			text-align: center;
			@include global.mincho();
			font-size: 1.8rem;
			line-height: 1;
			font-weight: 700;
			color: global.$black;
			margin-bottom: 32px;
		}
	}
}


@media only screen and (max-width: 1120px) {

}

@media screen and (max-width: 800px) {
	#entry-body {
		margin-top: 32px;
		padding-left: global.$sp-side-padding;
		padding-right: global.$sp-side-padding;
		h1 {
			font-size: 2rem;
			margin-right: 0;
			text-align: center;
			&.faq {
				text-align: left;
			}
		}
		#thumbnail {
			margin-top: 24px;
		}
		.entry-meta {
			margin-top: 24px;
			flex-wrap: wrap;
			justify-content: center;
			date {
				font-size: 1.2rem;
				line-height: 1;
				margin-right: 24px;
			}
			.author {
				margin-right: 0;
				.img {
					width: 32px;
					height: 32px;
				}
				figcaption {
					font-size: 1.2rem;
				}
			}
			.socials {
				flex-basis: 100%;
				margin-top: 24px;
				font-size: 2.2rem;
				justify-content: space-around;
				a {
					position: relative;
					margin: 0;
				}
			}
		}
		.entry-body {
			margin-top: 24px;
			font-size: 1.3rem;
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			ul,
			ol,
			p {
				margin-bottom: 2em;
			}
			h2 {
				color: global.$black;
				font-weight: 600;
				font-size: 1.6rem;
				margin-bottom: 1em;
			}
			h3 {
				font-size: 1.4rem;
				margin-bottom: 1em;
			}
			&.about {
				table {
					width: 100%;
					box-sizing: border-box;
					display: block;
					tr {
						border-bottom: 1px solid global.$very-light-gray;
						box-sizing: border-box;
						display: flex;
						flex-flow: column;
						flex-wrap: wrap;
						margin-bottom: 1em;
						padding-bottom: 1em;
						th,
						td {
							flex-basis: 100%;
							width: 100%;
							border-bottom: none;
							padding: 0;
							ul {
								margin-top: 1em;
								display: block;
								li {
									flex-basis: auto;
									margin-bottom: .5em;
									font-size: 1.1rem;
								}
							}
						}
					}
				}
			}
		}
		.entry-footer {
			margin-top: 48px;
			hr {
				width: 120px;
				margin: 0 auto 36px;
				border: none;
				background: global.$light-gray;
				height: 1px;
			}
			.socials {
				flex: 1;
				font-size: 2.2rem;
				justify-content: space-around;
				a {
					position: relative;
					margin-right: 0;
				}
			}
			.author {
				margin-top: 48px;
				display: block;
				text-align: center;
				.img {
					display: inline-block;
					width: 64px;
					height: 64px;
					margin-right: 0;
				}
				figcaption {
					margin-top: 12px;
					flex: none;
					h4 {
						font-size: 1.3rem;
						margin-bottom: 12px;
					}
					.author-profile {
						text-align: left;
						font-size: 1.2rem;
						line-height: 1.6;
					}
				}
			}
		}
		.relative {
			margin-top: 8px;
			padding-bottom: 8px;
			h3 {
				font-size: 1.6rem;
			}
		}
	}
}

@media screen and (max-width: 320px) {

}
